export type BackendUser = {
  sub: string;
  email: string;
  title: string;
  firstName: string;
  lastName: string;
  job: string;
  gender: string;
  phoneNumber: string;
  birthdate: string;
  groups: string[];
  isActive: boolean;
  address: {
    street: string;
    houseNumber: string;
    zip: string;
    city: string;
  };
  prescriptionState: PrescriptionState;
  prescriptionStartTime: number;
  prescriptionOriginalRequest?: string;
  prescriptionActiveFollowup?: string;
  doctorId?: string;
  factor?: string;
  factor2?: string;
  studio?: string;
  studioSource?: string;
  stripeId?: string;
};

export type Contact = {
  name: string;
  email: string;
};

export type UserGroup = {
  name: string;
  description: string;
};

export enum PrescriptionState {
  IDLE = 'IDLE',
  AWAITING = 'AWAITING', // Subscriptions
  AWAITING_ONE_TIME = 'AWAITING-ONE-TIME', // One time order
  ACTIVE = 'ACTIVE', // Subscriptions
  ACTIVE_ONE_TIME = 'ACTIVE-ONE-TIME', // One time order
  PAUSED = 'PAUSED',
  FOLLOWUP = 'FOLLOWUP',
}

export type PrescriptionStateUi = {
  prescriptionState: PrescriptionState;
  prescriptionStartTime: number;
};

export type Subscription = {
  id: string;
  status: string;
  current_period_end: number;
  created: number;
  paused: boolean;
  payment_method: {
    type: string;
    identifier: string;
  };
  payment_intent: {
    status: string | null;
  };
  product: {
    id: string | null;
    name: string | null;
    freeProduct: boolean;
  };
  price: {
    id: string;
    unit_amount: number;
    currency: string;
  };
};
